var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.list.length > 0)?_c('div',{class:['list', _vm.styleName],style:({ display: _vm.flex ? 'flex' : 'block' })},_vm._l((_vm.list),function(item,index){return _c('a',{key:index,staticClass:"item",attrs:{"hover-class":"none"},on:{"click":() => {
      _vm.$router.push({
        path: 'detail',
        query: {
          id: item.id,
        },
      })
    }}},[_c('div',{staticClass:"info",class:_vm.isSearch ? 'active' : ''},[_c('div',{staticClass:"photo"},[_c('img',{attrs:{"src":item.cover.url,"mode":"aspectFill"}})]),_c('div',{staticClass:"text"},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.name))]),(item.symbol && item.symbol.length)?_c('div',{staticClass:"label"},[(item.symbol && item.symbol.length > 3)?_vm._l((item.symbol.slice(0, 3)),function(e){return _c('div',{key:e.id,style:({ backgroundColor: e.colorHex })},[_vm._v(" "+_vm._s(e.name)+" ")])}):_vm._l((item.symbol),function(e){return _c('div',{key:e.id,style:({ backgroundColor: e.colorHex })},[_vm._v(" "+_vm._s(e.name)+" ")])})],2):_vm._e()]),_c('div',{staticClass:"other"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"time"},[_vm._v(" 报名时间: "+_vm._s(new Date(item.registrationStartTime).Format('yyyy.MM.dd') + ' - ' + new Date(item.registrationEndTime).Format('yyyy.MM.dd'))+" ")])])])])])])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }