<template>
  <div :class="['list', styleName]" v-if="list.length > 0" :style="{ display: flex ? 'flex' : 'block' }">
    <a class="item" v-for="(item, index) in list" :key="index" hover-class="none" @click="() => {
      $router.push({
        path: 'detail',
        query: {
          id: item.id,
        },
      })
    }
      ">
      <div class="info" :class="isSearch ? 'active' : ''">
        <div class="photo">
          <img :src="item.cover.url" mode="aspectFill" />
        </div>
        <div class="text">
          <div>
            <div class="title">{{ item.name }}</div>
            <div class="label" v-if="item.symbol && item.symbol.length">
              <template v-if="item.symbol && item.symbol.length > 3">
                <div v-for="e in item.symbol.slice(0, 3)" :key="e.id" :style="{ backgroundColor: e.colorHex }">
                  {{ e.name }}
                </div>
              </template>
              <template v-else>
                <div v-for="e in item.symbol" :key="e.id" :style="{ backgroundColor: e.colorHex }">
                  {{ e.name }}
                </div>
              </template>
            </div>
          </div>
          <div class="other">
            <div class="left">
              <!-- 							<div class="source" v-if="item.source">{{ item.source }}</div> -->
              <div class="time">
                报名时间:
                {{
                  new Date(item.registrationStartTime).Format('yyyy.MM.dd') +
                  ' - ' +
                  new Date(item.registrationEndTime).Format('yyyy.MM.dd')
                }}
              </div>
            </div>
            <!-- 						<div class="right" v-if="item.comment_count > 0">
							<img src="/static/imgs/icon_comment.png"></img>
							<text>{{ item.comment_count }}</text>
						</div>
						<div class="right div" v-else>
							<img src="/static/imgs/icon_div.png"></img>
							<text>{{ item.read }}</text>
						</div> -->
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'articleList',
  props: {
    list: {
      type: Array,
      default: function (e) {
        return []
      },
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    styleName: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="less" scoped>
/*文章列表*/
.list {
  margin-top: 2px; 

  .item {
    display: block;
    padding: 12px 20px 14px;
    margin-bottom: 24px;
    background-color: #FFFFFF;

    .info {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 4px;
      width: 100%;

      .text {
        width: calc(100% - 190px);
        flex-grow: 1; 
        display: flex;
        flex-direction: column;
        justify-content: space-between; 

        .title { 
          width: 100%; 
          /* flex-grow: 1;
						flex-shrink: 1; */
          height: 32px;
          font-size: 24px;
          font-family: AlibabaPuHuiTiR;
          color: #333333;
          line-height: 32px;
          // display: -webkit-box;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          // word-break: break-all;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 1;
          // height: 96px;
          // margin-bottom: 8px;
        }

        .label {
          display: flex;
          width: 100%;
          margin-top: 12px;

          div {
            width: 78px;
            height: 24px;
            background: #f9e5c0;
            border-radius: 2px;
            margin-right: 16px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #666666;
            line-height: 24px;
            text-align: center;
          }
        }

        .other {
          /* flex-grow: 1;
						flex-shrink: 1; */
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #999;
          line-height: normal;
          margin-bottom: 8px;

          .left {
            display: flex;
            flex-grow: 1;
            flex-shrink: 1;

            .time {
              height: 22px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 22px;
            }

            .source {
              display: -webkit-box;
              text-overflow: ellipsis;
              word-break: break-all;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              width: 140px;
              margin-right: 16px;
            }
          }

          .right {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: 5px;

            img {
              flex-grow: 1;
              flex-shrink: 1;
              width: 28px;
              height: 28px;
              margin-right: 12px;
            }

            text {
              flex-grow: 1;
              flex-shrink: 1;
              margin-top: -6px;
            }
          }

          .div {
            img {
              width: 40px;
              height: 40px;
              margin-right: 5px;
            }
          }
        }
      }

      .photo {
        flex-shrink: 0;
        img {
          height: 130px;
          width: 130px;
          border-radius: 4px;
          margin-right: 24px;
        }
      }
    }

    .active {
      padding-bottom: 32px;
      border-bottom: 2px solid #f1f2f0;
    }

    .line {
      display: bock;
      width: 100%;
      height: 1px;
      margin-top: 22px;
      background: #e8e8e8;
    }

    &:last-child {
      .line {
        //display: none;
      }

      //padding-bottom: 30px;
    }
  }

}

.indexStyle {
  justify-content: space-between;
  background-color: unset;

  .item {
    background: linear-gradient(180deg, #F4F6F9 0%, #FEFEFE 100%);
    box-shadow: 0px 1px 12px 0px rgba(28, 36, 46, 0.06);
    border-radius: 2px;
    border: 2px solid #FFFFFF;
    width: 584px;
    margin-bottom: 40px;
  }
}
.listStyle {
  .item { 
background: #FFFFFF;
box-shadow: 0px 1px 10px 0px rgba(0,0,0,0.06);
border-radius: 2px;
width: 856px; 
  }
}
</style>
