<template>
  <div class="page">
    <div class="page_title_contain">
      <header class="other_page_head">
        <div class="common_warp header_wrap">
          <div
            class="icon"
            @click="
              () => {
                $router.push('/home')
              }
            "
          >
            <img src="../assets/eamaster_logo_title_index.png" alt="" />
          </div>
          <div class="menu other_page_menu">
            <a-input-search
              class="input_search"
              placeholder="夏校、机器人、耶鲁、纽约…"
              enter-button="搜索"
              size="large"
              v-model="parameter.keyword"
              :maxLength="100"
              @search="onSearch"
              list="data-list"
            >
              <a-icon
                slot="prefix"
                type="search"
                :style="{ color: '#cdcdcd' }"
              />
            </a-input-search>
            <datalist id="data-list">
              <option
                v-for="(item, i) in history"
                :key="i"
                :value="item"
              ></option>
            </datalist>
          </div>
          <div class="language other_page_language">
            <span class="pointer">中文</span><span class="line">|</span
            ><span
              class="pointer"
              @click="
                () => {
                  $router.push('english')
                }
              "
              >English</span
            >
          </div>
        </div>
      </header>
      <div class="title">
        <div class="fool_title">
          {{ $route.query.name }}
        </div>
      </div>
      <div class="sort_wrap">
        <div class="sort">
          <a-tabs
            v-if="!this.$route.query.name"
            default-active-key=""
            v-model="tabsValue"
            @change="tabsChange"
          >
            <a-tab-pane
              v-for="item in projectLabelOptions"
              :key="item.id"
              :tab="item.name"
            ></a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="clear_warp">
        <div class="clear" v-show="showClear">
          <span
            >共<span style="color: red">{{ total }}</span
            >条</span
          >
          <span class="pointer" @click="clearSearch()">清除条件</span>
        </div>
      </div>
      <main class="common_warp">
        <div class="content" v-show="total > 0">
          <article-list :list="artList[current - 1]" :styleName="'listStyle'" />
          <div class="pagination_wrap">
            <a-pagination
              v-model="current"
              :total="total"
              :page-size="pageSize"
              @change="ChangePage"
            >
            </a-pagination>
          </div>
        </div>
        <div class="content" v-show="!total">
          <div class="none_state">
            <img src="@/assets/pc_none.png" alt="" />
            <p>未找到相关内容</p>
          </div>
        </div>
        <div class="label">
          <div
            class="label_group"
            v-for="(item, index) in tagMultipleSelectionList"
            :key="item.id"
          >
            <div class="group_title">{{ item.name }}</div>
            <div class="label_item">
              <a-checkbox-group
                v-model="selectedLabelList[index]"
                @change="
                  (v) => {
                    changeSelection(v, index)
                  }
                "
              >
                <a-row>
                  <a-col v-for="(e, key) in item.label" :key="key" :span="12">
                    <a-checkbox :value="e.id">
                      {{ e.name }}
                    </a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import {
  moreFloorActive,
  moreAccessActive,
  getProjectLabelOptions,
  getMajorLabelOptions,
  getGradeLabelOptions,
  search,
  getHomeLabels,
  getClassTypeLists,
} from '@/api/home'
import articleList from '@/components/articleList'
import util from '@/utils/utils'
import storage from '@/utils/storage'
export default {
  name: 'more',
  components: { articleList },
  data() {
    return {
      selectedLabelList: [],
      showClear: false,
      current: 1,
      pageSize: 10,
      total: 0,
      artList: [],
      projectLabelOptions: [],
      tabsValue: '',
      majorLabelOptions: [],
      majorValue: [],
      gradeLabelOptions: [],
      gradelValue: [],
      api: undefined,
      classList: [],
      parameter: {
        keyword: undefined,
        label: undefined,
      },
      tagMultipleSelectionList: [],
      typeId: '',
      history: [],
    }
  },
  created() {
    this.loadData()
  },
  // more页的activated钩子
  activated() {
    // isUseCache为false时才重新刷新获取数据
    // 因为对list使用keep-alive来缓存组件，所以默认是会使用缓存数据的
    if (!this.$route.meta.isUseCache) {
      console.log('zhixingle activated isUseCache刷新')
      this.total = 0
      this.current = 1
      this.tabsValue = this.$route.query.typeId || ''
      this.artList = [] // 清空原有数据
      this.loadData() // 这是我们获取数据的函数
    } else {
      this.$route.meta.isUseCache = false
    }
  },
  methods: {
    loadData() {
      this.typeId = this.$route.query.typeId || ''
      console.log(storage)
      this.history = storage.get('history') || []
      if (this.$route.query.typeId) {
        this.getCheckBoxList(this.$route.query.typeId)
      } else {
        this.getCheckBoxList()
      }
      this.api = this.$route.query.access ? moreAccessActive : moreFloorActive
      if (!this.$route.query.name) {
        this.parameter.keyword = this.$route.params.keyword
        this.api = search
      }
      this.getOptions()
      this.getList()
    },
    async getList() {
      let d = {
        entryType: this.$route.query.entryType,
        objectId: this.$route.query.objectId,
        ...this.parameter,
        typeId: this.$route.query.typeId,
        type: this.typeId || undefined,
      }
      /*  if (!(this.parameter.label && this.parameter.label.length)) {
        this.parameter = []
      } */
      if (this.parameter.label && this.parameter.label.length) {
        d.label = this.parameter.label
      } else {
        Reflect.deleteProperty(d, 'label')
      }
      const { data } = await this.api({
        ...d,
      })
      if (data) {
        this.total = data.length
        this.artList = util.getNeedArr(data, 10)
      } else {
        this.artList = []
        this.total = 0
      }

      if (
        ((this.parameter && this.parameter.keyword) ||
          (this.parameter.label && this.parameter.label.length)) &&
        this.api == search
      ) {
        console.log(this.parameter.label.length)
        this.showClear = true
      } else {
        this.showClear = false
      }
    },
    async getOptions() {
      const { data } = await getClassTypeLists()
      this.projectLabelOptions = [{ id: '', name: '全部' }, ...data]
    },
    ChangePage(current, v) {
      console.log(current, v)
    },
    // 顶部切换栏
    tabsChange(value) {
      this.selectedLabelList = []
      this.parameter.label = []
      this.tabsValue = value
      this.typeId = value
      this.getCheckBoxList(value)
      this.getList()
    },
    // 拿标签树
    async getCheckBoxList(id = '') {
      const { data } = await getHomeLabels(id)
      this.tagMultipleSelectionList = data
    },
    // 选择
    changeSelection(v, i) {
      let arr = this.selectedLabelList.reduce((arr, item) => {
        arr = [...arr, ...item]
        return arr
      }, [])
      console.log(arr)
      this.parameter.label = [...arr]
      this.getList()
    },
    onSearch() {
      if (
        this.parameter.keyword &&
        !this.history.includes(this.parameter.keyword)
      ) {
        if (this.history.length >= 10) {
          this.history.splice(this.history.length - 1, 1)
        }
        this.history.unshift(this.parameter.keyword)
        storage.set('history', this.history)
      }
      console.log(this.parameter.keyword)

      this.getList()
    },
    clearSearch() {
      this.parameter = {}
      this.gradelValue = []
      this.majorValue = []
      this.tabsValue = ''
      this.getList()
    },
  },
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 300px);
}
.page_title_contain {
  .sort_wrap {
    width: 100%;
    background-color: #fff;
  }

  .sort {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;

    :deep(.ant-tabs-bar) {
      margin-bottom: 0;
      border-bottom: unset;
    }

    :deep(.ant-tabs-nav .ant-tabs-tab) {
      margin-right: 18px;
      padding: 0 4px 8px 4px;
      line-height: 21px;
    }
  }
}

.title {
  width: 100%;
  background-color: #ffffff;
  padding-bottom: 22px;

  .fool_title {
    margin: 0 auto;
    font-size: 32px;
    font-family: AlibabaPuHuiTiR;
    color: #333333;
    line-height: 44px;
    text-align: center;
    width: 1200px;
    background: #ffffff;
    border-bottom: 1px solid #f1f2f0;
    padding-bottom: 11px;
  }
}

.about {
  width: 100%;
  flex: 1;

  main {
    display: flex;

    .label {
      overflow-x: hidden;
      margin-top: 24px;
      margin-left: 24px;

      .label_group {
        padding: 20px;
        margin-bottom: 24px;
        width: 320px;
        height: 350px;
        background: #ffffff;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
        border-radius: 2px;
        overflow-y: auto;

        .group_title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 30px;
          padding-bottom: 12px;
          border-bottom: 1px solid #f1f2f0;
        }

        .label_item {
          margin-top: 12px;

          :deep(.ant-checkbox-group) {
            width: 100%;
          }

          :deep(.ant-col) {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
  .clear_warp {
    width: 1200px;
    margin: 0 auto;
  }
  .clear {
    width: 856px;
    font-size: 18px;
    font-family: AlibabaPuHuiTiR;
    color: #666666;
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .common_warp {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;

    .content {
      flex: 1;
      margin-top: 24px;
      margin-bottom: 100px;
      position: relative;

      .pagination_wrap {
        display: flex;
        justify-content: center;
      }
      .none_state {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        img {
          width: 132px;
          height: 123px;
          margin-bottom: 12px;
        }
        p {
          font-size: 14px;
          font-family: AlibabaPuHuiTiR;
          color: #666666;
          line-height: 20px;
          text-align: center;
        }
      }
    }
  }
}
/deep/.ant-tabs-tab-prev-icon-target,
.ant-tabs-tab-next-icon-target {
  vertical-align: 1px;
  font-size: 14px;
}

/deep/.ant-tabs-tab-next .ant-tabs-tab-next-icon-target,
:root .ant-tabs-tab-next-icon-target {
  vertical-align: 1px;
  font-size: 14px;
}
/deep/.ant-checkbox + span {
  word-break: break-all;
}
</style>
