export class Storage {
  constructor(mainStorage = window.localStorage) {
    this.mainStorage = mainStorage;
  }

  /**
   * 获取数据
   *
   * @param {string} key
   * @returns {Object, Array, String, null}
   */
  get(key) {
    try {
      const value = this.mainStorage.getItem(key);
      if (!value) {
        return value;
      }
      return JSON.parse(value);
    } catch (e) {
      return this.mainStorage.getItem(key) || "";
    }
  }

  /**
   * 设置数据
   *
   * @param {string} key
   * @param {any} data
   * @param {boolean} isJson
   */
  set(key, data, isJson = true) {
    try {
      if (isJson) {
        this.mainStorage.setItem(key, JSON.stringify(data));
      } else {
        this.mainStorage.setItem(key, data);
      }
    } catch (e) {
      console.log("error => ", `set ${key} storage error`);
    }
  }

  /**
   * 删除数据
   *
   * @param {string,string[]} key
   */
  remove(key) {
    try {
      if (Array.isArray(key) && key.length > 0) {
        key.forEach((val) => {
          this.mainStorage.removeItem(val);
        });
      }
      if (typeof key === "string") {
        this.mainStorage.removeItem(key);
      }
    } catch (e) {
      console.log("error => ", `del ${key} storage error`);
    }
  }

  /**
   * 清除全部
   */
  clear() {
    try {
      this.mainStorage.clear();
    } catch (e) {
      console.log("error => ", "clear all storage error");
    }
  }
}

export const sessionStorage = new Storage(window.sessionStorage);
export default new Storage();
